import React from 'react';
import clsx from 'clsx';
import * as PropTypes from 'prop-types';
import { Button as MUIButton } from '@material-ui/core';

import useStyles from './styles';

import { FontAwesomeIcon } from '$common/fontawesome-pro/react-fontawesome';

const Button = (props) => {
    const { children, endIcon, variant, color, className, ...restProps } = props;

    const classes = useStyles();

    return (
        <MUIButton
            color={color}
            className={clsx(classes.root, className)}
            classes={{
                endIcon: classes.endIcon,
            }}
            endIcon={endIcon ? <FontAwesomeIcon icon={['fad', endIcon]} /> : undefined}
            variant={variant}
            {...restProps}
        >
            {children}
        </MUIButton>
    );
};

Button.defaultProps = {
    endIcon: 'angle-right',
    variant: 'text',
    color: 'primary',
    className: undefined,
};

Button.propTypes = {
    children: PropTypes.node.isRequired,
    endIcon: PropTypes.string,
    variant: PropTypes.string,
    color: PropTypes.string,
    className: PropTypes.string,
};

export default Button;
