import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    content: {
        padding: theme.spacing(0, 4, 4, 4),
    },
    closeIconButton: {
        position: 'absolute',
        right: 30,
        top: 30,
        zIndex: 5,
    },
    menuItem: {
        margin: theme.spacing(4, 0, 2.5, 0),
        display: 'block',
    },
    menuItemActive: {
        color: theme.palette.logo.blue.main,
    },
    actions: {
        background: '#f8faff',
        justifyContent: 'center',
    },
}));
