import React from 'react';
import clsx from 'clsx';
import { graphql, Link, useStaticQuery } from 'gatsby';
import * as PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, Grid, IconButton, Typography } from '@material-ui/core';

import StartNowButton from '$landing-page/components/start-now-button';

import SubMenu from '../sub-menu';
import { checkMenuItemIsActive } from '../utils';
import useStyles from './styles';

import { FontAwesomeIcon } from '$common/fontawesome-pro/react-fontawesome';

const query = graphql`
    query hamburgerMenuDialog {
        site {
            siteMetadata {
                menu {
                    title
                    url
                    showSubMenuOnHeader
                    subMenu {
                        title
                        url
                        description
                        icon
                        swapIconOpacity
                    }
                }
            }
        }
    }
`;

const HamburgerMenuDialog = (props) => {
    const { open, onClose } = props;

    const classes = useStyles();
    const queryData = useStaticQuery(query);

    const { menu } = queryData.site.siteMetadata;

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <IconButton onClick={onClose} className={classes.closeIconButton} size="small" edge="end">
                <FontAwesomeIcon icon={['fas', 'times']} size="xs" fixedWidth />
            </IconButton>
            <DialogContent className={classes.content}>
                {menu.map((menuItem) => (
                    <React.Fragment key={menuItem.title}>
                        <Grid item>
                            <Typography
                                variant="h6"
                                color="textSecondary"
                                className={clsx(classes.menuItem, {
                                    [classes.menuItemActive]: checkMenuItemIsActive(menuItem),
                                })}
                                component={menuItem.url ? Link : 'div'}
                                to={menuItem.url || undefined}
                            >
                                {menuItem.title}
                            </Typography>
                        </Grid>
                        {menuItem.showSubMenuOnHeader !== false && <SubMenu items={menuItem.subMenu} />}
                    </React.Fragment>
                ))}
            </DialogContent>
            <DialogActions className={classes.actions}>
                <StartNowButton />
            </DialogActions>
        </Dialog>
    );
};

HamburgerMenuDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default HamburgerMenuDialog;
